<template>
  <b-table
    :fields="fields"
    :items="items"
    :empty-text="$t('GERAL.NENHUM_REGISTRO')"
    head-variant="light"
    stacked="md"
    responsive
    show-empty
    striped
    hover
  >
    <template #cell(acoes)="item">
      <b-dropdown variant="outline-default">
        <b-dropdown-item @click="editar(item.item)">
          {{ $t('GERAL.EDITAR') }}
        </b-dropdown-item>
        <b-dropdown-item @click="excluir(item.item)">
          {{ $t('GERAL.EXCLUIR') }}
        </b-dropdown-item>
      </b-dropdown>
    </template>
  </b-table>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

// Services:
import DepositosService from '@/common/services/depositos/depositos.service.js';

export default {
  props: {
    items: { type: Array, default: Array },
  },
  data() {
    return {
      fields: [
        {
          key: 'descricao',
          label: this.$t('SUPRIMENTOS.NOME'),
          sortable: true,
        },
        {
          key: 'acoes',
          label: this.$t('GERAL.ACOES'),
        },
      ],
    };
  },
  methods: {
    editar(item) {
      this.$emit('editar', 'deposito', item);
    },

    async excluir(item) {
      const respostaExclusao = await mensagem.confirmacao(
        `Tem certeza que deseja excluir o estoque ${item.descricao}`,
        this.$t('ACADEMICO.AVISO_NAO_PODE_DESFAZER_ACAO')
      );

      if (respostaExclusao.isConfirmed) {
        this.$store.dispatch(START_LOADING);
        DepositosService.excluir(item.id)
          .then(() => {
            mensagem.sucesso(this.$t('GERAL.SUCESSO'));
            this.$emit('atualiza-tabela');
          })
          .catch((err) => {
            mensagem.showErrors(err);
          })
          .finally(() => {
            this.$store.dispatch(STOP_LOADING);
          });
      }
    },
  },
};
</script>
