<template>
  <div>
    <titulo-depositos @cadastrar="openModal" />

    <cabecalho
      title="SUPRIMENTOS.DEPOSITOS"
      description="SUPRIMENTOS.DEPOSITO_DESCRICAO"
      linkRetornar="suprimentos"
      :btnPrimary="true"
      @primary-method="openModal('deposito')"
    />

    <tabela-deposito
      :items="depositos"
      @atualiza-tabela="getDepositos"
      @editar="openModal"
    />

    <modal-deposito
      :exibir="modais.deposito"
      :dados="modais.depositoDados"
      @atualiza-tabela="getDepositos"
      @fechar="closeModal"
    />
  </div>
</template>
<script>
// Utils & Aux:
import breadcrumb from '@/common/utils/breadcrumb';
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import modais from '@/common/utils/modais';

// Services:
import DepositosService from '@/common/services/depositos/depositos.service.js';

// Components:
import TabelaDeposito from './components/Tabela';
import ModalDeposito from './components/modal/Index';
import TituloDepositos from '@/views/suprimentos/depositos/components/TituloDepositos';

export default {
  components: {
    TituloDepositos,
    TabelaDeposito,
    ModalDeposito,
  },
  data() {
    return {
      depositos: [],
      modais: {
        deposito: false,
        depositoDados: {},
      },
    };
  },
  mounted() {
    breadcrumb.definir(this.$store, [
      { titulo: 'SUPRIMENTOS.SUPRIMENTOS' },
      { titulo: 'SUPRIMENTOS.DEPOSITOS' },
    ]);
    this.getDepositos();
  },
  methods: {
    getDepositos() {
      this.$store.dispatch(START_LOADING);
      DepositosService.obterTodos()
        .then(({ data }) => {
          this.depositos = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÕES MODAL:
    openModal(key, item) {
      return modais.abrirModal(this, key, item);
    },
    closeModal(key) {
      return modais.fecharModal(this, key);
    },
  },
};
</script>
