<template>
  <div class="row">
    <div class="col-12">
      <input-text
        ref="descricao"
        v-model="form.descricao"
        :label="$t('SUPRIMENTOS.NOME')"
        :placeholder="$t('SUPRIMENTOS.NOME')"
        type="text"
        :maxLength="150"
        required
      />
    </div>
    <div class="col-12">
      <input-select-search
        ref="historicoLancamento"
        :label="$t(`FINANCEIRO.INCLUIR_HISTORICO_ESTOQUE`)"
        :options="opcoesHistoricoLancamento"
        v-model="form.historicoLancamentoId"
        required
      />
    </div>
  </div>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

// Components:
import { InputText, InputSelectSearch } from '@/components/inputs';

// Services:
import HistoricoLancamentoService from '@/common/services/historico-lancamento/historico-lancamento.service.js';

export default {
  props: {
    form: { type: Object, default: Object },
  },
  data() {
    return {
      historicosSelecionado: '',
      opcoesHistoricoLancamento: [],
    };
  },
  components: {
    InputText,
    InputSelectSearch,
  },
  mounted() {
    this.$emit('refs', this.$refs);
    this.emitirDados();
  },
  methods: {
    emitirDados() {
      this.$store.dispatch(START_LOADING);
      HistoricoLancamentoService.obterEstoque()
        .then(({ data }) => {
          this.opcoesHistoricoLancamento = data.map((row) => ({
            ...row,
            text: row.descricao,
            value: row.id,
          }));
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
  },
};
</script>
