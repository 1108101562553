<template>
  <modal
    id="modalSuprimentoDeposito"
    titulo="SUPRIMENTOS.DEPOSITO"
    :exibir="exibir"
    :campos="$refs"
    tamanho="xl"
    @fechar="fecharModal"
  >
    <form-deposito :form="dados" @refs="getRefs" />
    <template #modal-footer>
      <div class="d-flex justify-content-between w-100">
        <b-button variant="secondary" @click="fecharModal">
          {{ $t('GERAL.FECHAR') }}
        </b-button>
        <b-button variant="primary" @click="salvar">
          {{ $t('GERAL.SALVAR') }}
        </b-button>
      </div>
    </template>
  </modal>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

// Services:
import DepositosService from '@/common/services/depositos/depositos.service.js';

// Utils & Aux:
import helpers from '@/common/utils/helpers';

// Components:
import FormDeposito from './Form.vue';
import Modal from '@/components/modal/Modal';

export default {
  props: {
    exibir: { type: Boolean, default: false },
    dados: { type: Object, default: Object },
  },
  components: {
    Modal,
    FormDeposito,
  },
  data() {
    return {
      refs: [],
      form: {},
    };
  },
  watch: {
    dados() {
      this.form = this.dados;
    },
  },
  methods: {
    // FUNÇÕES VALIDAÇÃO FORMULÁRIO:
    getRefs(refs) {
      this.refs = refs;
    },
    validarFormulario() {
      return helpers.validarFormulario(this.refs);
    },
    // FUNÇÕES CRUD:
    salvar() {
      if (!this.validarFormulario()) return;
      !this.form.id ? this.cadastrar() : this.editar();
    },
    cadastrar() {
      this.$store.dispatch(START_LOADING);
      DepositosService.criar(this.form)
        .then(() => {
          mensagem.sucesso(this.$t('GERAL.SUCESSO'));
          this.$emit('atualiza-tabela');
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
          this.fecharModal();
        });
    },
    editar() {
      this.$store.dispatch(START_LOADING);
      DepositosService.editar(this.form)
        .then(() => {
          mensagem.sucesso(this.$t('GERAL.SUCESSO'));
          this.$emit('atualiza-tabela');
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
          this.fecharModal();
        });
    },
    // FUNÇÕES MODAL:
    fecharModal() {
      this.$emit('fechar', 'deposito');
    },
  },
};
</script>
